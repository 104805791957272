import React from "react";
import path from "path";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import { useHasScroll } from "has-scroll-hook";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import SEO from "../components/SEO";

const LazyReactTypingEffect = React.lazy(() => import("react-typing-effect"));

const Posts = ({ posts, pathPrefix }) => {
  return (
    <Grid container spacing={3}>
      {posts.slice(1, Math.min(5, posts.length)).map(
        ({
          node: {
            excerpt,
            fileAbsolutePath,
            frontmatter: { id, title, featuredImage }
          }
        }) => {
          const postDate = path
            .basename(fileAbsolutePath)
            .split("-")
            .splice(0, 3)
            .join("-");
          return (
            <Grid item xs={12} sm={6} key={id}>
              <Card
                featuredImage={featuredImage}
                title={title}
                url={`/${pathPrefix}/${id}`}
                postDate={postDate}
                excerpt={excerpt}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  studios: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end"
      // flexDirection: "column"
    },
    fontSize: 64
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      alignItems: "center"
    }
  }
}));

export default function HomeTemplate({
  data: {
    site: {
      siteMetadata: {
        title,
        description,
        shortDescription,
        templates: {
          posts: { pathPrefix }
        }
      }
    },
    allMdx: { edges: posts }
  }
}) {
  /* Get the vertical scrollbar offset as a boolean value. */
  const hasScroll = useHasScroll();
  const classes = useStyles();
  const isSSR = typeof window === "undefined";

  return (
    <Layout elevateAppBar={hasScroll}>
      <SEO
        title={shortDescription.toUpperCase().replace(".", "")}
        description={description}
      ></SEO>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          paddingTop={6}
          paddingBottom={12}
          paddingX={4}
          justifyContent="center"
        >
          <Fade in timeout={500}>
            <div className={classes.logo}>
              <Typography color="textSecondary" variant="h1">
                <div style={{ display: "flex" }}>
                  <span>
                    chemical{" "}
                    <span style={{ color: "rgb(195, 4, 4)" }}>heads</span>
                  </span>
                </div>
                <div className={classes.studios}>studios</div>
              </Typography>
              <Box paddingTop={4}>
                <Typography
                  className={classes.description}
                  color="textSecondary"
                  variant="h4"
                >
                  {!isSSR && (
                    <React.Suspense fallback={<div />}>
                      <LazyReactTypingEffect
                        speed="125"
                        typingDelay="100"
                        eraseDelay="9999"
                        erase
                        style={{ textTransform: "uppercase" }}
                        text={shortDescription}
                      />
                    </React.Suspense>
                  )}
                </Typography>
              </Box>
            </div>
          </Fade>
        </Box>
      </Box>
      <Box flexGrow={1} marginX="auto" width="100%" maxWidth={960}>
        <Box padding={1}>
          <Box paddingBottom={3}>
            {posts.length >= 1 && (
              <Card
                parallax={false}
                featuredImage={posts[0].node.frontmatter.featuredImage}
                title={posts[0].node.frontmatter.title}
                url={`/${pathPrefix}/${posts[0].node.frontmatter.id}`}
                postDate={path
                  .basename(posts[0].node.fileAbsolutePath)
                  .split("-")
                  .splice(0, 3)
                  .join("-")}
                excerpt={posts[0].node.excerpt}
              />
            )}
          </Box>
          <Posts posts={posts} pathPrefix={pathPrefix} />
          {posts.length > 1 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding={1}
              marginTop={1}
            >
              <Button
                fullWidth={isMobile}
                variant="outlined"
                size="large"
                color="primary"
                component={Link}
                to={`/${pathPrefix}/page/1`}
              >
                View All
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($limit: Int!) {
    site {
      siteMetadata {
        title
        shortDescription
        templates {
          posts {
            pathPrefix
          }
        }
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/posts/" } }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fileAbsolutePath
          frontmatter {
            id
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
